import gql from 'graphql-tag';

const AUTHENTICATE_USER = gql`
  mutation authentication {
    authentication {
      user {
        id
        name
        contactEmail
        profilePicture
      }
      authToken
    }
  }
`;

const LIST_ENABLED_USERS = gql`
  query EnabledUsers {
    enabledUsers {
      id
      name
      contactEmail
    }
  }
`;

const LIST_USERS = gql`
  query Users {
    users {
      id
      name
      profilePicture
      enabled
    }
  }
`;

const LIST_USERS_ROLES = gql`
  query UsersRoles($input: UsersRolesPaginatedInput!) {
    usersRoles(input: $input) {
      usersRoles {
        userId
        userName
        profilePicture
        contactEmail
        enabled
        roleId
        roleName
        isUserSynched
        applicationId
        applicationName
      }
      usersRolesCount
    }
  }
`;

const RE_SYNC_USERS = gql`
  mutation ReSyncUserAndTimeEntries($userId: Float!) {
    reSyncUserAndTimeEntries(userId: $userId)
  }
`;

const USER_SETTINGS = gql`
  query UserSettings($contactEmail: String!) {
    userSettings(contactEmail: $contactEmail) {
      id
      contactEmail
      name
      viewType
      manualMode
      lightMode
      sideMenuOpen
    }
  }
`;

const UPDATE_USER_PREFERENCES = gql`
  mutation UpdateUserPreferences($input: UserPreferences!) {
    updateUserPreferences(input: $input) {
      id
      contactEmail
      name
      lightMode
      manualMode
      sideMenuOpen
      viewType
      dateFormat
    }
  }
`;

const UPDATE_USER_STUDIO = gql`
  mutation UpdateUserStudio($userId: Float!) {
    updateUserStudio(userId: $userId) {
      studioId
    }
  }
`;

const GET_USER = gql`
  query user($id: Float, $email: String) {
    user(id: $id, email: $email) {
      id
      name
      contactEmail
      enabled
      profilePicture
      applicationId
      role {
        id
        name
        hierarchy
      }
      studio {
        id
        name
      }
    }
  }
`;

const LIST_USER_ALLOCATIONS = gql`
  query UserAllocations($userId: Float) {
    userAllocations(userId: $userId) {
      floatProjectId
      clientName
      startDate
      endDate
    }
  }
`;

export {
  AUTHENTICATE_USER,
  LIST_ENABLED_USERS,
  LIST_USERS,
  LIST_USERS_ROLES,
  RE_SYNC_USERS,
  USER_SETTINGS,
  UPDATE_USER_PREFERENCES,
  UPDATE_USER_STUDIO,
  GET_USER,
  LIST_USER_ALLOCATIONS,
};
