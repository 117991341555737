export const INTEGER_RADIX = 10;

export const ENTER_KEY_NAME = 'Enter';
export const SPACE_BAR_KEY_NAME = ' ';
export const ARROW_UP_NAME = 'ArrowUp';
export const ARROW_DOWN_NAME = 'ArrowDown';
export const TAB_KEY_NAME = 'Tab';

export const DEFAULT_TAG_ID = 0;
export const DEFAULT_PROJECT_AND_CLIENT_ID = 0;
export const DEFAULT_PROJECT_ID = DEFAULT_PROJECT_AND_CLIENT_ID;
export const DEFAULT_CLIENT_ID = DEFAULT_PROJECT_AND_CLIENT_ID;
export const DEFAULT_TASK_ID = 0;

export const INPUT_DATE_MASK = '99/99/9999';

export const MIN_PERCENTAGE_REPORT_DISPLAY = 5;

export const PROJECT_ID_FILTER = 'project';
export const CLIENT_ID_FILTER = 'client';
export const USER_ID_FILTER = 'user';
export const TIME_ENTRY_ID_FILTER = 'timeEntry';
export const STUDIOS_ID_FILTER = 'studios';
export const CLIENT_GROUP_ID_FILTER = 'clientGroup';

export const WEEKLY_ID = 'week';
export const DAILY_ID = 'day';
export const MONTHLY_ID = 'month';
export const HIDE_ID = 'hide';

export const TIMER_PAGE_NAME = 'Timer';
export const REPORT_PAGE_NAME = 'Reports';

export const REPORT_SUMMARY_TAB_NAME = 'Summary';
export const REPORT_DETAILED_TAB_NAME = 'Detailed';
export const REPORT_WEEKLY_TAB_NAME = 'Weekly';

export const SORT_DIRECTION_ASCENDING = 'ASC';
export const SORT_DIRECTION_DESCENDING = 'DESC';

export const DEFAULT_TOOLTIP_OPEN_DELAY = 1000;
export const ONE_HUNDRED_MILLISECONDS = 100;

export const DEFAULT_TOOLTIP_LOCATION = 'bottom';

export const TOAST_DEFAULT_SUCCESS = {
  duration: 4000,
  position: 'bottom-left',
  isClosable: true,
  status: 'success',
};

export const TOAST_DEFAULT_ERROR = {
  title: 'An error has ocurred',
  duration: 4000,
  position: 'bottom-left',
  status: 'error',
};

export const DEBOUNCE_DELAY = 500;

export const NO_CLIENT_TEXT = 'No client';
export const NO_PROJECT_TEXT = 'No project';
export const NO_TAG_TEXT = 'No tag';

export const INITIAL_TIME = '0:00:00';

export const TODAY_TOTAL_TEXT = 'TODAY';
export const WEEK_TOTAL_TEXT = 'WEEK TOTAL';

export const NEW_LINE_CHAR = '\n';

export const BEFORE_LIMIT_DATE_INCLUSIVE = 'beforeLimitDateInclusive';
export const BEFORE_LIMIT_DATE = 'beforeLimitDate';
export const AFTER_LIMIT_DATE_INCLUSIVE = 'afterLimitDateInclusive';
export const AFTER_LIMIT_DATE = 'afterLimitDate';

export const ELAPSED_TIME_LENGTHS = {
  7: '64px',
  8: '72px',
  9: '84px',
  10: '90px',
};

export const APP_SETTING_PROJECT_REQUIRED = 'project_required';
export const APP_SETTING_TAG_REQUIRED = 'tag_required';
export const APP_SETTING_FLOAT_PROJECTS_TAGS = 'float_projects_tags';

export const DEFAULT = 'default';
export const BILLABLE = 'billable';
export const NOT_BILLABLE = 'notBillable';

export const BILLABLE_TRUE_OPTION = 'Only billable';
export const BILLABLE_FALSE_OPTION = 'Non billable';
export const BILLABLE_NEUTRAL_OPTION = 'Both';
export const BILLABLE_DEFAULT_FILTER_NAME = 'Billable';

export const TO_BILL_TRUE_OPTION = 'Only to bill';
export const TO_BILL_FALSE_OPTION = 'Non to bill';
export const TO_BILL_NEUTRAL_OPTION = 'Both';
export const TO_BILL_DEFAULT_FILTER_NAME = 'To bill';

export const PROJECT_FILTER_NAME = 'Project';
export const CLIENT_FILTER_NAME = 'Client';

export const PLAY_FROM_TIME_ENTRY_REQUIRED_FIELDS_ERROR_MESSAGE =
  'The running time entry must have all required fields set before starting a new one!';

export const GENERIC_ERROR_MESSAGE = 'Sorry, something went wrong!';

export const DEFAULT_UPDATE_NOTIFICATION =
  'Please update the app to ensure you continue to have the full range of features and improve your experience.';

export const DUPLICATE_TEXT = 'Duplicate';
export const PIN_AS_FAVORITE_TEXT = 'Pin as Favorite';
export const SPLIT_TEXT = 'Split';
export const DELETE_TEXT = 'Delete';

export const MEMBERS_SORT_FIELDS = {
  NAME_ASC: 1,
  NAME_DESC: 2,
  EMAIL_ASC: 3,
  EMAIL_DESC: 4,
  ROLE_ASC: 5,
  ROLE_DESC: 6,
  SYNC_ASC: 7,
  SYNC_DESC: 8,
  APPLICATION_ASC: 9,
  APPLICATION_DESC: 10,
};

export const SORT_FIELDS = {
  NAME: 'name',
  ROLE: 'role',
  EMAIL: 'email',
  SYNC: 'sync',
  APPLICATION: 'application',
};

export const CLIENTS_SORT_FIELDS = {
  NAME_ASC: 1,
  NAME_DESC: 2,
};

export const MAX_FAVORITES = 10;

export const DAYS_AUTOCOMPLETED_ENTRIES = 14;

export const SETTINGS_GENERAL_TAB_NAME = 'General';
export const SETTINGS_PROFILE_TAB_NAME = 'Profile';

export const MEMBERS_GENERAL_TAB_NAME = 'General';
export const MEMBERS_GROUPS_TAB_NAME = 'Groups';

export const CLIENTS_GENERAL_TAB_NAME = 'General';
export const CLIENTS_GROUPS_TAB_NAME = 'Groups';

export const APP_NAME = 'Moove it';

export const TIME_ENTRY_NO_DESCRIPTION_ERROR =
  'Time entry must have description';

export const COMMON_TIME_ENTRIES = [
  { name: 'Daily', tagName: '1 - Comm/Management/Marketing' },
  { name: 'Refinement', tagName: '1 - Comm/Management/Marketing' },
  { name: 'Planning', tagName: '1 - Comm/Management/Marketing' },
  { name: 'Review', tagName: '1 - Comm/Management/Marketing' },
  { name: 'Retrospective', tagName: '1 - Comm/Management/Marketing' },
  { name: '1:1 <person name>', tagName: '1 - Comm/Management/Marketing' },
  { name: 'Sync with <person name>', tagName: '1 - Comm/Management/Marketing' },
  { name: 'Technical problems', tagName: '8 - Admin/People/Ops' },
];

export const VIEW_TYPE_OPTIONS_KEY = 'selectedViewTypeOption';
export const VIEW_TYPE_OPTIONS = [
  { id: 'PROJECTS', name: 'Projects breakdown' },
  { id: 'CLIENTS', name: 'Clients breakdown' },
];

export const DEFAULT_PROJECT_VIEW_TYPE_SUBJECT = {
  id: 'PROJECTS',
  name: 'Projects breakdown',
};

export const REPORTS_PAGINATION_TAKE = [25, 50, 75, 100].map(take => ({
  id: parseInt(take, 10),
  name: parseInt(take, 10),
}));

export const FIRST_DAY_OF_WEEK = 1;

export const DAYS_IN_A_WEEK = 7;
